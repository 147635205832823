// @import url(//fonts.googleapis.com/earlyaccess/notosanskr.css); /*light:100 regular:400 bold:700*/
@import url(./notosans.css);
html { height: 100% }
body { 
  position: relative;
  background-color: #fafcff; 
  min-height: 100%;
  background-image: url(../assets/images/bg-pattern.png);
  background-repeat: repeat;
}

.has-browser-alert {
  padding-top: 60px;
  #side-menu {
    top: 60px !important;
  }
}
.browser-alert-message {
  height: 60px;
  background-color: #ebebeb;
  position: fixed;
  top: 0; left: 0;
  width: 100%;
}

.full-bg {
  position: fixed;
  top: 0; bottom: 0; left: 0; right: 0;
  background-image: url(../assets/images/bg.png);
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 1000px rgba(0,0,0,0.5);
}

.login-panel {
  width: 100%;
  max-width:400px; 
  margin:50px auto 0;
  .login-logo {
    width: 70%;
  }

  @media only screen and (min-width: 601px) { 
    margin-top: 150px; 
    .login-logo {
      width: 50%;
    }
  }
  @media only screen and (min-width: 993px) { 
    margin-top: 150px; 
    .login-logo {
      width: 50%;
    }
  }
}

.login-container {
  .card .card-content .card-title {
    color:#000;
    font-weight:500;
    margin-top:30px;
  }
}

.login-fail-message {
  font-size: 13px;
}

.card-progress {
  margin: 0;
  position: absolute;
  top: 0; left: 0;
}

.as-tabs-wrap {
  overflow: hidden;
  padding-bottom: 12px;
}

.as-modal-header {
  position: absolute;
  top: 0; left: 0; right: 0;
  height: 32px;

  img {
    height: 22px;
    display: block;
    margin: 5px 0 0 10px;
  }

  .close {
    position: absolute;
    top: 4px; right: 5px;
  }
}

.progress.as-modal-progress {
  margin: 0;
  position: absolute;
  bottom: 0px;
  left: 0;
  display: none;

  &.show { display: block; }
}

.main-container {
  padding-bottom: 150px;
}

.content-wrap {
  padding-top: 60px;

  &.w-form {
    max-width: 650px;
    margin: 0 auto;
  }
}

.side-menu-nav-wrap {
  height: 54px;
  .side-menu-nav {
    width: 300px;
    position: absolute;
    top: 0; left: 0;
  }
  @media only screen and (min-width: 601px) {
    height: 64px;
  }
}
.sidebar-logo {
  top: 0px; right: 0px; left: 0px;
  height: 54px;
  overflow: hidden;
}

.sidebar-logo img {
  width: 175px;
  // margin: 14px auto 0;
  margin: 14px 0 0 30px;
  display: block;
}

.sub-count {
  font-size: 90%;
  display: inline-block;
  padding: 0 6px;
}

@media only screen and (min-width: 601px) {
  .sidebar-logo { height: 64px; }
  .sidebar-logo img { margin-top: 18px; }
}

a, button {
  outline: none;
}

.text-bold { 
  font-weight: bold !important;
}

.table-wrap {
  // padding: 0px 20px;
  background-color: white;
}
.round-padd-box {
  padding: 35px 20px 5px;

  &.with-header {
    padding-top: 15px;
  }
}

.clickable-tr {
  cursor: pointer;
  &:hover td {
    background-color: #ebebeb;
  }
}

// .table-wrap {
//   overflow-y: auto;
//   table { min-width: 650px; }
// }
.table-wrap tr {
  td:first-child, th:first-child {
    padding-left: 20px;
  }
}

.mainnav-progress {
  margin: 0;
}

.dev-icon {
  color: #00a0e9;
}

#side-menu {
  li {
    &.active {
      background-color: #353535;
      // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.27);
      > a {
        color: white;
        font-weight: bold;
        &:hover { background-color: transparent; }
      }
    }
    a {
      position: relative;
      color: #fff;
      &:hover {
        background-color: rgba(0,0,0,0.25);
      }
    }
  }

  .main-manu-for-sidenav > li {
    &.active > a:before {
      content: " ";
      display: block;
      position: absolute;
      top: 20px; left: 12px;
      width: 8px; height: 8px;
      border-radius: 4px;
      background-color: #106090;
      // border-left: 7px solid #106090;
    }
  }

  .submenu-in-mainmenu {
    background-color: #2a2a2a;
    box-shadow: inset 0px -1px 2px rgba(0,0,0,0.25);
    li {
      color: #000;
      &.active {
        background-color: transparent;
        box-shadow: none;
        > a { color: #9bb7c7; }
      }
      > a { 
        color: #696969; 
        &:hover { background-color: rgba(0,0,0,0.25); }
      }
    }
  }
}



.form-filters {
  background-color: white;
  padding: 10px 10px 0;
}

.button-row { padding: 10px 0; }
.text-left { 
  text-align: left; 
  td, th { text-align: left; }
}
.text-right { 
  text-align: right; 
  td, th { text-align: right; }
}
.text-center { text-align: center; }

.page-description {
  > p {
    border-bottom: 1px solid #aeb4b9;
    padding-bottom: 10px;
  }

  .page-description-group {
    h5 {
      font-size: 1.3rem;
      font-weight: bold;
      color: #000;
    }
    h6 {
      font-size: 13px;
      color: #00a0e9;
    }
    p {
      font-size: 13px;
      word-break: keep-all;
      border-bottom: 1px dashed #aeb4b9;
      padding-bottom: 14px;
    }
  }

  ol, ul {
    li {
      font-size: 13px;
      padding: 10px 0;
    }
  }

  .strong {
    color: #4c82a2;
    font-weight: bold;
  }

  small {
    color: #9e9e9e;
    font-size: 84%;
  }
}

.not-in-screen {
  position: absolute;
  top: -1000px;
}

.label-for-file {
  cursor: pointer;
  font-size: 0 !important;
  display: inline-block;
  padding: 1px;
  transition: background-color 300ms;
  &:hover {
    background-color: #f0f0f0;
    background-color: rgba(0,0,0,0.15);
  }
}

.as-footer {
  position: absolute;
  bottom: 0px; right: 0px;
  padding: 10px 42px;
  text-align: right;

  font-size: 12px;
  /* color: #b5b5b5; */

  strong {
    font-size: 110%;
  }

  &.as-footer-login {
    position: relative;
    top: auto; right: auto;
    text-align: center;
  }
}

.html-parser {
  position: absolute;
  position: fixed;

  top: -2000px;
  width: 1px; height: 1px;
}

.sidenav li.manual-download > a {
  line-height: 16px;
  padding-top: 10px;
  height: 52px;
}

.nav-exp-date {
  font-size: 13px;
  color: #c3c3c3;
}

.pagination {
  display: inline-block;
  .ellipse {
    color: #a0a0a0;
    line-height: 30px;
  }
}